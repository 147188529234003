// import React, { useContext } from "react";
// import PropTypes from "prop-types";
// import { useTheme, Box, Grid, AppBar, Toolbar, IconButton, Typography, SvgIcon, Hidden, Avatar } from '@mui/material';
// import { Menu as MenuIcon } from 'react-feather';
// import { Link } from 'react-router-dom';
// // import { AuthContext } from "src/context/Auth";
// // import Logo from "src/component/Logo";

// const TopBar = ({ selectedTab, onTabChange, onMobileNavOpen, ...rest }) => {
//   const theme = useTheme();
// //   const history = useHistory();
// //   const auth = useContext(AuthContext);
// //   const userData = auth.userData;

//   return (
//     <AppBar
//       elevation={0}
//       color="inherit"
//       sx={{
//         boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)",
//         backgroundColor: 'white',
//       }}
//       {...rest}
//     >
//       <Toolbar
//         sx={{
//           padding: { xs: "0px 20px 0px 5px", sm: "7px 30px 7px 30px" },
//           minHeight: "50px !important",
//           '& .MuiAppBar-positionFixed': {
//             position: "unset"
//           }
//         }}
//       >
//         {(window.location.pathname === "/" || window.location.pathname === "/forget" || window.location.pathname === "/verify-otp" || window.location.pathname === "/reset-password") ? (
//           ""
//         ) : (
//           <>
//             <Hidden lgUp>
//               <IconButton
//                 color="white"
//                 onClick={onMobileNavOpen}
//                 sx={{ padding: "0px" }}
//               >
//                 <SvgIcon>
//                   <MenuIcon sx={{ color: "white", fontSize: "25px" }} />
//                 </SvgIcon>
//               </IconButton>
//             </Hidden>
//           </>
//         )}
//         &nbsp; &nbsp;
//         <Box
//           sx={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             flexWrap: "wrap",
//             '& svg': {
//               color: theme.palette.text.primary,
//               '@media(max-width:767px)': {
//                 fontSize: "18px",
//               },
//             },
//             '& .leftBox': {
//               width: "100%",
//               maxWidth: "42px",
//               '@media(max-width:md)': {
//                 maxWidth: "42px",
//               },
//               '@media(max-width:xs)': {
//                 maxWidth: "42px",
//               },
//               '& img': {
//                 width: "100%",
//                 maxWidth: "42px",
//                 '@media(max-width:sm)': {
//                   maxWidth: "42px",
//                   paddingLeft: "0 !important",
//                   paddingTop: "8px",
//                   paddingBottom: "8px",
//                 },
//                 '@media(max-width:483px)': {
//                   maxWidth: "42px",
//                 },
//               },
//             },
//           }}
//         >
//           <Grid container alignItems="center">
//             <Grid item lg={3} md={3} sm={4} xs={6}>
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//     {/* <Box className="leftBox">
//       <Link to="/dashboard" style={{ display: "flex", alignItems: "center" }}> */}
//       <img
//   src="images/sdkLogo.svg"
//   alt="healthcare_logo"
// //   onClick={() => navigate("/")}
// width={"40%"}
//   style={{ cursor: "pointer" }}
// />
//       {/* </Link>
//     </Box> */}
//                 <Link to="/dashboard" style={{ textDecoration: "none" }}>
//                   {/* <Typography color="secondary" variant="h3">RECRUITMENT</Typography> */}
//                 </Link>
//               </Box>
//             </Grid>
//             <Hidden xsDown>
//               <Grid item lg={4} md={6} sm={5}></Grid>
//             </Hidden>
//             <Grid item lg={5} md={3} sm={3} xs={6}>
//               {/* <Box className="displayEnd">
//                 <Box
//                   className={classes.avatarmain}
//                   onClick={() => history.push("/profile")}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <Avatar
//                     src={
//                       userData?.profilePic
//                         ? userData?.profilePic
//                         : "images/editprofile.png"
//                     }
//                   />
//                 </Box>
//               </Box> */}
//             </Grid>
//           </Grid>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// TopBar.propTypes = {
//   onMobileNavOpen: PropTypes.func,
// };

// TopBar.defaultProps = {
//   onMobileNavOpen: () => {},
// };

// export default TopBar;

// // export function TopBarData() {
// //   const theme = useTheme();
// //   const history = useHistory();
// //   const auth = useContext(AuthContext);

// //   return (
// //     <Box display="flex" alignItems="center" justifyContent="flex-end">
// //       <Hidden xsDown>
// //         <Box>
// //           <Typography variant="h5">NFT Marketplace</Typography>
// //           <Typography variant="body1" sx={{ color: "#ffffff9c" }}>
// //             example@gmail.com
// //           </Typography>
// //         </Box>
// //       </Hidden>
// //       &nbsp; &nbsp;
// //       <Avatar
// //         src={
// //           auth?.userData?.profilePic
// //             ? `${auth?.userData?.profilePic}`
// //             : "https://picsum.photos/533/357"
// //         }
// //       />
// //     </Box>
// //   );
// // }

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Grid,
  Box,
} from "@mui/material";
import { Menu as MenuIcon } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import logo from "../../../assets/Logo.svg";

// import Logo from "src/component/Logo";

// Styles
const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  padding: "7px 30px",
  background: "#1F9874",
  minHeight: "50px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px 0px 5px",
  },
  "& .MuiAppBar-positionFixed": {
    position: "unset",
  },
}));

const MainHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  "& svg": {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  "& .leftBox": {
    width: "100%",
    maxWidth: "42px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
    },
    "& img": {
      width: "100%",
      maxWidth: "42px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "42px",
        paddingLeft: "0 !important",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
}));

const TopBar = ({
  selectedTab,
  onTabChange,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  // Placeholder for theme setting and auth
  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      className={className}
      color="inherit"
      sx={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <ToolbarStyled>
        {window.location.pathname === "/" ||
        window.location.pathname === "/forget" ||
        window.location.pathname === "/verify-otp" ||
        window.location.pathname === "/reset-password" ? null : (
          <Hidden mdUp>
            <IconButton
              color="white"
              onClick={onMobileNavOpen}
              sx={{ padding: "0px" }}
            >
              <SvgIcon>
                <MenuIcon style={{ color: "white", fontSize: "25px" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
        )}
        &nbsp; &nbsp;
        <MainHeader>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& img": {
                    cursor: "pointer",
                    width: "60%",
                    "@media (min-width:1440px)": {
                      width: "40%",
                    },
                  },
                }}
              >
                <img src={logo} alt="Logo" />

                <Link to="/dashboard" style={{ textDecoration: "none" }}></Link>
              </Box>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "end" }}
              item
              lg={9}
              md={9}
              sm={8}
              xs={6}
            >
              <IoNotifications
                onClick={() => navigate("/notifications")}
                color="#fff"
                size="30px"
                style={{ cursor: "pointer" }}
              />
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5} />
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}></Grid>
          </Grid>
        </MainHeader>
      </ToolbarStyled>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
