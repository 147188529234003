import { lazy } from "react";
// import DashboardLayout from "./layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout";
import DashBoardLayOut from "../src/layouts/DashBoardLayOut/index.js";

export const routes = [
  {
    exact: true,
    auth: false,
    path: "/testComponent",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/EmployerManagement/test.jsx")
    ),
  },
  {
    exact: true,
    auth: false,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/Login.jsx")),
  },
  {
    exact: true,
    auth: false,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ForgotPassword.js")),
  },
  {
    exact: true,
    auth: false,
    path: "/otpVerification",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/OTPVerification.js")),
  },
  {
    exact: true,
    auth: true,
    path: "/resetPassword",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ResetPassword.js")),
  },
  {
    exact: true,
    auth: true,
    path: "/dashboard",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/DashboardMain/LandingPage.js")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/notifications",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/DashboardMain/NotificationPage.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/SubAdminListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin/addSubAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/AddSubAdmin.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin/viewSubAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/ViewSubAdmin.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/subAdmin/editSubAdmin",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/subAdmin/EditSubAdmin.jsx")
    ),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/MyAccountManagement/AccountProfilePage.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/profile/editProfile",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/MyAccountManagement/EditProfile.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/profile/changePassword",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/MyAccountManagement/ChangePassword.jsx")
    ),
  },

  {
    exact: true,
    auth: true,
    path: "/skills",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/SkillManagement/SkillManagement.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/skills/addSkills",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/SkillManagement/AddSkill.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/skills/editSkills",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/SkillManagement/EditSkill.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/skills/addJobCategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/SkillManagement/AddJobCategory.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/skills/editJobCategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/SkillManagement/EditJobCategory.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/locations",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/JobLocationManagement/JobLocationListing.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/locations/addLocations",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/JobLocationManagement/AddLocation.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/locations/editLocations",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SettingManagement/JobLocationManagement/EditLocation.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/employerListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/EmployerManagement/EmployerListing")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/employerListing/employerDetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/EmployerManagement/EmployerDetails")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/employerListing/employerDetails/editEmployerdetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/EmployerManagement/EditEmployerDetails.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/employerListing/viewEmployerRequests",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/EmployerManagement/ViewEmployerRequests")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/employerListing/viewEmployerRequests/employerRequestDetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/EmployerManagement/EmployerRequestDetails"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/candidateListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CandidateManagement/CandidateListing")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/candidateListing/candidateDetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/CandidateManagement/CandidateDetails")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/BlogManagement.jsx")
    ),
  },
  // {
  //   exact: true,
  //   path: "/blogListing",
  //   layout: DashBoardLayOut,
  //   component: lazy(() => import("./views/pages/Dashboard/BlogManagement/BlogListing")),
  // },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/addBlog",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/AddBlog")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/editblog",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/EditBlog.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/viewblog",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/ViewBlog.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/addBlogCategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/AddBlogCategory.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/viewBlogCategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/ViewBlogCategory.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/blogManagement/editBlogCategory",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/BlogManagement/EditBlogCategory.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/jobListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/JobManagement/JobListing.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/jobListing/jobDetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/JobManagement/JobDetails.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/SubscriptionListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SubscriptionManagement/SubscriptionListing.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/SubscriptionListing/viewSubscriptionDetails",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SubscriptionManagement/ViewSubscriptionDetails.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/transactionListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/TransactionManagement/TransactionListing.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/StaticContentListing.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/privacyPolicy",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/PrivacyPolicy.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/editPrivacyPolicy",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/EditPrivacyPolicy.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/aboutUs",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/AboutUs/AboutUs.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/editAboutUs",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/AboutUs/EditAboutUs.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/termsAndConditions",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/TermsAndConditions.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/editTermsConditions",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/TermsAndCondition/EditTermsConditions.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/FAQs",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQs/FAQs.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/FAQs/AddFAQ",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQs/AddFAQ.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/FAQs/EditFAQ",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQs/EditFAQ.jsx")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/banner",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Banner/Banner.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/banner/addBanner",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Banner/AddBanner.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/banner/editBanner",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Banner/EditBanner.jsx"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/testimonials",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Testimonials/TestimonialsListing"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/testimonials/addtestimonials",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Testimonials/AddTestimonials"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/testimonials/edittestimonials",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Testimonials/EditTestimonials"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/testimonials/viewTestimonial",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Testimonials/ViewTestimonial"
      )
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/brands",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Brand/ListBrands")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/brands/addbrands",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Brand/AddBrands")
    ),
  },
  {
    exact: true,
    auth: true,
    path: "/staticContentListing/brands/editbrand",
    layout: DashBoardLayOut,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Brand/EditBrand")
    ),
  },
];
