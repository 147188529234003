import React, { Suspense, Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createMuiTheme } from "./theme";
import AuthContext from "./context/Auth";
import PageLoading from "./components/PageLoading";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import Unauthorized from "./components/Unauthorized";
import { routes } from "./routes";
import "./scss/main.css";

function App() {
  const theme = createMuiTheme({ theme: "light", direction: "ltr" });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthContext>
            <Router>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

function RenderRoutes(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [unauthorized, setUnauthorized] = useState(false);
  const [hideComponent, setHideComponent] = useState(false);

  const defaultAccessibleRoutes = [
    "/",
    "/forgot",
    "/otpverification",
    "/resetPassword",
    "/notifications",
    "/profile",
  ];

  // Function to get the loggedin status from cookies
  const getLoggedIn = () => {
    const loggedInCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("loggedin="));
    if (!loggedInCookie) {
      return false; // Default to not logged in if cookie is not found
    }
    const loggedInValue = loggedInCookie.split("=")[1];
    return loggedInValue === "true";
  };

  useEffect(() => {
    const loggedIn = getLoggedIn();

    // Skip the permission check for default accessible routes
    if (defaultAccessibleRoutes.includes(location.pathname)) {
      return;
    }

    const getPermissionsFromCookie = () => {
      const permissionsCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("permissions="));

      if (!permissionsCookie) {
        return [];
      }
      console.log(permissionsCookie);
      const permissionsString = permissionsCookie.split("=")[1];

      try {
        return permissionsString
          ? JSON.parse(decodeURIComponent(permissionsString))
          : [];
      } catch (error) {
        console.error("Error parsing permissions cookie:", error);
        return [];
      }
    };

    const checkPermission = (path, permissions) => {
      // If `allPermissions` is true, grant access to all routes
      if (permissions.allPermissions) {
        return true;
      }

      // Check if the route is in the default accessible routes
      if (defaultAccessibleRoutes.includes(path)) {
        return true;
      }

      // Check the permissions for other routes
      return permissions?.permissions?.some(
        (permission) =>
          path.startsWith(permission?.route) && permission?.granted
      );
    };

    const permissions = getPermissionsFromCookie();
    console.log(permissions);
    const hasPermission = checkPermission(location.pathname, permissions || {});

    setUnauthorized(!hasPermission);
  }, [location.pathname, defaultAccessibleRoutes]);

  useEffect(() => {
    // Handle redirection for routes that require authentication
    console.log(location.pathname);
    // if (route.auth == false && !getLoggedIn()) {
    //   console.log("Redirecting to home route because user is not logged in.");
    //   navigate("/");
    // }
  }, [location.pathname, props.data, navigate]);

  useEffect(() => {
    if (unauthorized) {
      const timer = setTimeout(() => {
        navigate("/");
        setHideComponent(true); // Set hideComponent to true after the timeout
      }, 2500);

      // Clean up the timer when the component unmounts

      return () => clearTimeout(timer);
    }
  }, [unauthorized]);

  if (unauthorized && !hideComponent) {
    return <Unauthorized />;
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Toaster position="top-right" />
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component />
                    )}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
        {/* Handle unauthorized routes */}
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </Suspense>
  );
}

export default App;
