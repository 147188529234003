import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, styled, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'block',
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledButton = styled(Button)(({ theme, open, isActive }) => ({
  color: isActive ? '#1F9874' : theme.palette.primary.main,
  padding: '13px 20px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  marginBottom: '8px',
  letterSpacing: 0,
  width: '100%',
  fontWeight: '400',
  borderRadius: 0,
  backgroundColor: isActive ? '#fff' : open ? theme.palette.background.default : 'transparent',
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.background.active,
    '& .icon': {
      color: '#fff',
      marginRight:"6px"
    },
  },
}));

const StyledButtonLeaf = styled(Button)(({ theme, depth, isActive }) => ({
  color: isActive ? '#1F9874' : "white",
  // padding: '13px 20px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  marginBottom: '5px',
  borderRadius: 0,
  opacity: 1,
  fontWeight: '400',
  fontSize: '15px',
  paddingLeft: 16 + 8 * depth,
  backgroundColor: isActive ? '#fff' : 'transparent',
  '& .icon': {
    color: isActive ? '#0B1426' : '#fff',
    marginRight:"6px"
  },
  '&:hover': {
    color: '#1F9874',
    backgroundColor: '#fff',
    borderRadius: '0px',
    '& .icon': {
      color: '#0B1426',
      marginRight:"6px"
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  tabview,
  setSelectedTab,
  ...rest
}) => {
  const [open, setOpen] = useState(openProp);
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (tabview === 'Sniper') {
      setSelectedTab(tabview);
    }
  }, [tabview, setSelectedTab]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const isActive = location.pathname.includes(href);

  if (children) {
    return (
      <StyledListItem
        className={className}
        disableGutters
        key={title}
        {...rest}
      >
        <StyledButton onClick={handleToggle} open={open} isActive={isActive}>
          {Icon && <Icon style={{marginRight:"7px"}} className="icon" />} {" "}
          <span>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledButton>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      className={className}
      disableGutters
      key={title}
      {...rest}
    >
      <StyledButtonLeaf
        component={RouterLink}
        to={href}
        depth={depth}
        isActive={isActive}
      >
        {Icon && <Icon style={{marginRight:"7px"}} className="icon" />}
        <span>{title}</span>
        {Info && <Info />}
      </StyledButtonLeaf>
    </StyledListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tabview: PropTypes.string,
  setSelectedTab: PropTypes.func,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
